<template>
  <CModal
    :show="show"
    submit-form="create-update-delivery-area-form"
    :submit-disabled="submitDisabled"
    :submit-loading="submitLoading"
    variant="default"
    @toggle="onToggle"
  >
    <template v-slot:header>
      <template v-if="updateMode">
        {{ $t('deliveryArea.update', { 
          postalCodeWithCity: `${updateData.location.postalCode} ${updateData.location.city}`
        }) }}
      </template>
      <template v-else>
        {{ $t('deliveryArea.add') }}
      </template>
    </template>
    <CForm 
      id="create-update-delivery-area-form" 
      @submit="onSubmit"
      custom-save-button
      :error-fields-response="errorFieldsResponse"
    >
      <PostalCodeSearch
        v-autofocus
        v-if="!updateMode"
        :country-short="thisMerchant.countryShort"
        menu-class="max-h-[180px]"
        required
        :custom-error="postalCodeError"
        @select-id="onPostalCodeSelect"
      />
      <CTextField
        v-autofocus="updateMode"
        v-model="formData.additionalPrice"
        :label="$t('deliveryArea.additionalPrice')"
        icon="currency-euro"
        input-class="slashed-zero tabular-nums"
        :mask="{
          prefix: '',
          groupSeparator: '.',
          radixPoint: ',',
          alias: 'numeric',
          digits: 2,
          digitsOptional: false,
          textAlign: 'left',
          allowMinus: false,
          rightAlign: false,
          min: 0,
          max: 9999,
        }"
      >
        <template v-slot:message>
          {{ $t('deliveryArea.help.additionalPrice') }}
        </template>
      </CTextField>
      <CSwitch v-model="formData.ignoreDeliveryDistancePrice">
        {{ $t('deliveryArea.ignoreDeliveryDistancePrice') }}
      </CSwitch>
    </CForm>
    <template v-slot:submit>
      <template v-if="updateMode">
        {{ $t('save') }}
      </template>
      <template v-else>
        {{ $t('create') }}
      </template>
    </template>
  </CModal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PostalCodeSearch from '@/ui/components/PostalCodeSearch.vue';
import { ALL_DELIVERY_AREAS, THIS_MERCHANT } from '@/store/gettersTypes';
import germanDecimalParser from '@/utils/germanDecimalParser';
import { CREATE_DELIVERY_AREA, UPDATE_DELIVERY_AREA } from '@/store/actionTypes';

export default {
  components: {
    PostalCodeSearch,
  },

  model: {
    prop: 'show',
    event: 'toggle',
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    updateId: {
      type: Number,
      default: null,
    },
    updateData: {
      type: Object,
      default: null,
    },
  },

  data: () => ({
    formData: {
      postalCode: null,
      additionalPrice: null,
      ignoreDeliveryDistancePrice: false,
    },
    submitLoading: false,
    dupEntryError: false,
    errorFieldsResponse: null,
  }),

  computed: {
    ...mapGetters([THIS_MERCHANT, ALL_DELIVERY_AREAS]),
    updateMode() {
      if (this.updateId) return true;
      return false;
    },
    postalCodeError() {
      if (this.dupEntryError)
        return this.$t('deliveryArea.errors.alreadyExists', {
          postalCode: this.formData.postalCode,
        });
      return null;
    },
    submitDisabled() {
      if (this.postalCodeError) return true;
      return false;
    },
  },

  watch: {
    show: {
      immediate: true,
      handler(newVal) {
        if (newVal && this.updateData && this.updateId) {
          this.formData.postalCode = this.updateData.postalCode;
          this.formData.additionalPrice = this.updateData.additionalPrice;
          this.formData.ignoreDeliveryDistancePrice = this.updateData.ignoreDeliveryDistancePrice;
        }
      },
    },
    formData() {
      this.errorFieldsResponse = null;
    },
  },

  methods: {
    ...mapActions([CREATE_DELIVERY_AREA, UPDATE_DELIVERY_AREA]),
    onToggle(show) {
      this.$emit('toggle', show);
      this.formData.additionalPrice = null;
    },
    onPostalCodeSelect(id) {
      this.formData.postalCode = id;
      this.dupEntryError = this.allDeliveryAreas.findIndex((da) => da.postalCodeId === id) >= 0;
    },
    async onSubmit() {
      this.submitLoading = true;
      const additionalPrice = this.formData.additionalPrice || '0,00';
      const body = {
        postalCodeId: this.formData.postalCode,
        additionalPrice: germanDecimalParser(additionalPrice),
        ignoreDeliveryDistancePrice: this.formData.ignoreDeliveryDistancePrice,
      };
      try {
        if (this.updateMode) {
          await this.updateDeliveryArea({
            id: this.updateId,
            body,
          });
          this.submitLoading = false;
          this.onToggle(false);
        } else {
          await this.createDeliveryArea(body);
          this.submitLoading = false;
          this.onToggle(false);
        }
      } catch (e) {
        if (e.response.data.code === 'E_VALIDATION_FAILURE') {
          this.errorFieldsResponse = e.response;
        } else {
          throw e;
        }
      }
    },
  },
};
</script>
