<template>
  <div
    class="group flex justify-between items-center  rounded-lg
    px-3 py-2"
    :class="{
      'bg-gray-100 mb-3 md:mb-4': !standalone
    }"
  >
    <div class="select-none">
      <CTypo el="div" tstyle="subheadline">
        {{ locationName }}
      </CTypo>
      <CTypo tstyle="footnote1" class="text-gray-500 slashed-zero">
        {{ $t('deliveryArea.additionalPrice') }}: {{ $n(dataModel.additionalPrice, 'currency') }}
      </CTypo>
      <CTypo tstyle="footnote1" class="text-gray-500 slashed-zero">
        {{ 
          $t('deliveryArea.ignoreDeliveryDistancePrice') }}: {{ 
          dataModel.ignoreDeliveryDistancePrice ? $t('yes') : $t('no') 
        }}
      </CTypo>
    </div>
    <div
      class="filter transition duration-500"
      :class="filterClasses"
    >
      <CButton
        icon="pencil-outline"
        size="icon-small"
        inverted
        @click="$emit('update')"
      />
      <CButton
        variant="danger"
        inverted
        icon="trash-outline"
        size="icon-small"
        class="ml-2"
        @click="deleteDialog = true"
      />
    </div>
    <Portal to="overlays">
      <CModal
        v-model="deleteDialog"
        variant="dialog"
        submit-variant="danger"
        header-icon="exclamation-circle"
        :submit-countdown="1"
        :submit-loading="data.$loading === 'delete'"
        @submit="onDelete"
      >
        <template v-slot:header>
          {{ $t('actionQuestion.confirmDelete', { name: locationName }) }}
        </template>
        {{ $t('actionQuestions.deliveryArea.reallyWantToDelete', { name: locationName }) }}
        <template v-slot:submit>{{ $t('delete') }}</template>
      </CModal>
    </Portal>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: null,
    },
    // if not used in a list
    standalone: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    dataModel: null,
    deleteDialog: false,
  }),

  computed: {
    locationName() {
      if (this.dataModel) {
        return `${this.dataModel.location.postalCode} ${this.dataModel.location.city}`;
      }
      return '';
    },
    filterClasses() {
      if (this.standalone) return '';
      return `opacity-20 grayscale group-hover:grayscale-0 group-hover:opacity-100 touch:grayscale-0
      focus-within:grayscale-0 focus-within:opacity-100 touch:opacity-100`;
    },
  },

  watch: {
    data: {
      immediate: true,
      handler(newVal) {
        this.dataModel = newVal;
      },
    },
  },

  methods: {
    onDelete() {
      this.$emit('delete', this.data.id);
    },
  },
};
</script>
