<template>
  <CMap
    ref="map"
    height-class="relative flex flex-grow sm:min-h-[350px] md:min-h-[430px]
    xl:min-h-[500px] 2xl:min-h-[600px]"
    :config="{
      center: this.coordinates
    }"
    @map-loaded="onMapLoaded"
  >
    <CSegmentControl
      v-model="radius"
      class="absolute top-0 right-0 mt-3 px-3 w-full md:w-auto"
      nav-tabs-class="shadow-md border border-gray-300"
    >
      <CSegmentControlItem :label="$t('mapLabels.noRadius')" value="no" />
      <CSegmentControlItem label="10 km" value="10" />
      <CSegmentControlItem label="15 km" value="15" />
      <CSegmentControlItem label="25 km" value="25" />
    </CSegmentControl>
    <CMapMarker :coordinates="coordinates">
      <div
        class="w-10 h-10 rounded-full shadow-sm bg-primary text-on-primary
        inline-flex items-center justify-center"
      >
        <CIcon name="office-building" outline size="24" />
      </div>
    </CMapMarker>
    <CMapCircle
      v-if="radius !== 'no'"
      id="radiuscircle"
      ref="radiuscircle"
      :radius="circleRadius"
      :center="circleCenter"
      :steps="30"
      :options="{
        layer: {
          layout: {},
          paint: {
            'fill-color': '#0080ff', // blue color fill
            'fill-opacity': 0.3,
          },
        }
      }"
    />
    <div class="absolute bottom-0 left-0 w-full z-20 flex justify-center align-center md:mb-3">
      <div
        v-if="selectedPostalCodes.length > 0 || clickedPostalCode || radius !== 'no'"
        class="bg-gray-200 shadow-md"
        :class="{
          'w-full mx-3 border-gray-400': clickedPostalCode,
          'bg-opacity-75 backdrop-filter backdrop-blur-md border rounded-lg':
            clickedPostalCode || selectedPostalCodes.length > 0,
          'md:px-2 md:py-2': selectedPostalCodes.length > 0,
        }"
      >
        <template v-if="selectedPostalCodes.length > 0">
          <CButton
            class="mr-1 md:mr-2"
            @click="resetSelection"
          >
            {{ $t('cancelSelection') }}
          </CButton>
          <CButton
            variant="primary"
            :loading="submitLoading"
            @click="submitSelected"
          >
            {{ $t('deliveryArea.addSelected') }}
          </CButton>
        </template>
        <ListSelectorItem
          v-else-if="clickedPostalCode"
          :data="clickedDeliveryArea"
          standalone
          @update="onUpdate"
          @delete="onDelete(clickedDeliveryArea.id)"
        />
        <CButton
          v-else-if="selectedPostalCodes.length === 0 && radius !== 'no'"
          @click="selectFromRadius"
        >
          {{ $t('deliveryArea.addInRadius') }}
        </CButton>
      </div>
    </div>
    <Portal to="overlays">
      <CModal
        v-model="mapHelp"
        variant="dialog"
        header-icon="question-mark-circle"
        :button-props="{ hideCancel: true }"
        @submit="closeMapHelp"
        @close="closeMapHelp"
      >
        <template v-slot:header>
          {{ $t('help') }}
        </template>
        {{ $t('deliveryArea.help.useMapToSelect') }}
        <template v-slot:submit>
          {{ $t('ok') }}
        </template>
      </CModal>
    </Portal>
  </CMap>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import turfBbox from '@turf/bbox';
import turfBboxPolygon from '@turf/bbox-polygon';
import turfBooleanPointInPolygon from '@turf/boolean-point-in-polygon';
import turfCentroid from '@turf/centroid';
import { ALL_DELIVERY_AREAS, THIS_MERCHANT } from '@/store/gettersTypes';
import { CREATE_DELIVERY_AREA } from '@/store/actionTypes';
import ListSelectorItem from './ListSelectorItem.vue';

const MAP_HELP_STORE = 'cont-delivery-area-map-help';

export default {
  components: {
    ListSelectorItem,
  },

  props: {
    submitLoading: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    radius: 'no',
    clickedPostalCode: null,
    selectedPostalCodes: [],
    hoveredPostalCode: null,
    selectedFromRadius: false,
    country: 'DE',
    mapLoaded: false,
    mapHelp: true,
  }),

  computed: {
    ...mapGetters([THIS_MERCHANT, ALL_DELIVERY_AREAS]),
    clickedDeliveryArea() {
      const da = this.allDeliveryAreas.filter(
        (da) => da.location.postalCode === this.clickedPostalCode,
      );
      return da[0] || null;
    },
    highlightedLayers() {
      if (!this.mapLoaded) return [];
      return this.allDeliveryAreas.map((da) => da.location.postalCode);
    },
    coordinates() {
      return {
        lng: this.thisMerchant.address.lng,
        lat: this.thisMerchant.address.lat,
      };
    },
    circleCenter() {
      return [this.coordinates.lat, this.coordinates.lng];
    },
    circleRadius() {
      if (this.radius !== 'no') return Number(this.radius);
      return 0;
    },
    map() {
      return this.$refs.map?.map;
    },
  },

  watch: {
    highlightedLayers(newVal) {
      this.map.setFilter('plz-highlighted', ['in', 'plz', ...newVal]);
    },
    selectedPostalCodes(newVal) {
      this.map.setFilter('plz-selected', ['in', 'plz', ...newVal]);
    },
    clickedPostalCode(newVal) {
      if (newVal) {
        this.map.setFilter('plz-selected', ['in', 'plz', newVal]);
      } else if (!newVal && this.selectedPostalCodes.length === 0) {
        this.map.setFilter('plz-selected', ['in', 'plz', '']);
      }
    },
    submitLoading(newVal, oldVal) {
      if (oldVal && !newVal) {
        this.selectedPostalCodes = [];
      }
    },
    radius(newVal) {
      if (this.selectedFromRadius && newVal !== 'no') {
        this.$nextTick(() => {
          this.selectFromRadius();
        });
      } else if (this.selectedFromRadius && newVal === 'no') {
        this.resetSelection();
      }
    },
  },

  created() {
    this.mapHelp = !localStorage.getItem(MAP_HELP_STORE);
  },

  beforeDestroy() {
    this.map.removeLayer('plz-5-layer');
    this.map.removeLayer('plz-5-outline');
    this.map.removeLayer('plz-highlighted');
    this.map.removeLayer('plz-selected');
    this.map.removeSource('plz-5');
  },

  methods: {
    ...mapActions([CREATE_DELIVERY_AREA]),
    onMapLoaded() {
      this.map.addSource('plz-5', {
        type: 'vector',
        url: 'mapbox://phil1996s.45xi52ij',
      });
      this.map.addLayer({
        'id': 'plz-5-layer',
        'type': 'fill',
        'source': 'plz-5',
        'source-layer': 'plz-5stellig-9x3jjx',
        'paint': {
          'fill-outline-color': 'transparent',
          'fill-color': '#D1D5DB',
          'fill-opacity': 0.2,
        },
      });
      this.map.addLayer({
        'id': 'plz-5-outline',
        'type': 'line',
        'source': 'plz-5',
        'source-layer': 'plz-5stellig-9x3jjx',
        'paint': {
          'line-color': '#839592',
          'line-width': 2,
        },
      });
      this.onMapEvents();
      this.addHighlightedLayer();
      this.mapLoaded = true;
    },
    addHighlightedLayer() {
      const options = {
        'type': 'fill',
        'source': 'plz-5',
        'source-layer': 'plz-5stellig-9x3jjx',
        'filter': ['in', 'plz', ''],
      };
      this.map.addLayer({
        id: 'plz-highlighted',
        paint: {
          'fill-outline-color': '#059669',
          'fill-color': '#34D399',
          'fill-opacity': 0.75,
        },
        ...options,
      });
      this.map.addLayer({
        id: 'plz-selected',
        paint: {
          'fill-outline-color': '#1D4ED8',
          'fill-color': '#3B82F6',
          'fill-opacity': 0.75,
        },
        ...options,
      });
    },
    onMapEvents() {
      this.map.on('click', (e) => {
        const features = this.map.queryRenderedFeatures(e.point);

        // Limit the number of properties we're displaying for
        // legibility and performance
        const displayProperties = ['type', 'properties', 'id', 'source'];

        const displayFeatures = features
          .filter((feat) => feat.source === 'plz-5')
          .map((feat) => {
            const displayFeat = {};
            displayProperties.forEach((prop) => {
              displayFeat[prop] = feat[prop];
            });
            return displayFeat;
          });

        if (displayFeatures.length > 0) {
          const postalCode = displayFeatures[0].properties.plz;
          if (
            this.highlightedLayers.includes(postalCode) &&
            this.clickedPostalCode !== postalCode
          ) {
            this.clickedPostalCode = postalCode;
            this.selectedPostalCodes = [];
          } else if (this.clickedPostalCode === postalCode) {
            this.clickedPostalCode = null;
            this.selectedPostalCodes = [];
          } else if (!this.selectedPostalCodes.includes(postalCode)) {
            this.clickedPostalCode = null;
            this.selectedPostalCodes.push(postalCode);
            this.$emit('add-selected-postal-code', postalCode);
          } else if (this.selectedPostalCodes.includes(postalCode)) {
            this.clickedPostalCode = null;
            const index = this.selectedPostalCodes.findIndex((spc) => spc === postalCode);
            this.selectedPostalCodes.splice(index, 1);
            this.$emit('removed-selected-postal-code', postalCode);
          }
        }

        this.selectedFromRadius = false;
      });
    },
    selectFromRadius() {
      this.selectedPostalCodes = [];
      const { geometry } = this.$refs.radiuscircle.turfCircle;
      const bbox = turfBbox(geometry);
      const bboxPolygon = turfBboxPolygon(bbox);

      const features = this.map.queryRenderedFeatures(bboxPolygon);

      // Limit the number of properties we're displaying for
      // legibility and performance
      const displayProperties = ['type', 'properties', 'id', 'source'];

      const displayFeatures = features
        .filter((feat) => {
          if (feat.source === 'plz-5' && !this.highlightedLayers.includes(feat.properties.plz)) {
            const center = turfCentroid(feat);
            // const overlaps = turfBooleanOverlap(feat.geometry, geometry);
            const containsCenter = turfBooleanPointInPolygon(center.geometry, geometry);
            return containsCenter;
          }
          return false;
        })
        .map((feat) => {
          const displayFeat = {};
          displayProperties.forEach((prop) => {
            displayFeat[prop] = feat[prop];
          });
          return displayFeat;
        });

      displayFeatures.forEach((feature) => {
        const postalCode = feature.properties.plz;
        if (!this.selectedPostalCodes.includes(postalCode)) {
          this.clickedPostalCode = null;
          this.selectedPostalCodes.push(postalCode);
          this.$emit('add-selected-postal-code', postalCode);
        }
      });

      this.selectedFromRadius = true;
    },
    submitSelected() {
      this.$emit('submit-selected', {
        country: this.country,
        postalCodes: this.selectedPostalCodes,
        resetSelection: this.resetSelection,
      });
    },
    onUpdate() {
      this.$emit('update', {
        id: this.clickedDeliveryArea.id,
        data: this.clickedDeliveryArea,
      });
    },
    onDelete(id) {
      this.$emit('delete', id);
      this.clickedPostalCode = null;
    },
    closeMapHelp() {
      this.mapHelp = false;
      localStorage.setItem(MAP_HELP_STORE, true);
    },
    resetSelection(resetRadius = false) {
      this.selectedPostalCodes = [];
      this.selectedFromRadius = false;
      if (resetRadius) {
        this.radius = 'no';
      }
    },
  },
};
</script>
