<template>
  <div class="min-h-[240px]">
    <Item
      v-for="data in allDeliveryAreas"
      :key="data.id"
      :data="data"
      @update="openUpdateModal(data)"
      @delete="deleteArea(data.id)"
    />
    <div class="flex justify-center">
      <CButton
        v-if="allDeliveryAreas.length === 0"
        v-autofocus
        @click="onOpenModal"
      >
        {{ $t('deliveryArea.addFirst') }}
      </CButton>
      <CButton v-else v-autofocus @click="onOpenModal">{{ $t('deliveryArea.add') }}</CButton>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { ALL_DELIVERY_AREAS } from '@/store/gettersTypes';
import { DELETE_DELIVERY_AREA } from '@/store/actionTypes';
import Item from './ListSelectorItem.vue';

export default {
  components: {
    Item,
  },

  computed: {
    ...mapGetters([ALL_DELIVERY_AREAS]),
  },

  methods: {
    ...mapActions([DELETE_DELIVERY_AREA]),
    deleteArea(id) {
      this.deleteDeliveryArea(id);
    },
    openUpdateModal(data) {
      this.$emit('update', {
        id: data.id,
        data: {
          postalCodeId: data.postalCodeId,
          additionalPrice: data.additionalPrice,
          ignoreDeliveryDistancePrice: data.ignoreDeliveryDistancePrice,
          location: data.location,
        },
      });
    },
    onOpenModal() {
      this.$emit('open-create');
    },
  },
};
</script>
