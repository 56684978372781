var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CMap',{ref:"map",attrs:{"height-class":"relative flex flex-grow sm:min-h-[350px] md:min-h-[430px]\n  xl:min-h-[500px] 2xl:min-h-[600px]","config":{
    center: this.coordinates
  }},on:{"map-loaded":_vm.onMapLoaded}},[_c('CSegmentControl',{staticClass:"absolute top-0 right-0 mt-3 px-3 w-full md:w-auto",attrs:{"nav-tabs-class":"shadow-md border border-gray-300"},model:{value:(_vm.radius),callback:function ($$v) {_vm.radius=$$v},expression:"radius"}},[_c('CSegmentControlItem',{attrs:{"label":_vm.$t('mapLabels.noRadius'),"value":"no"}}),_c('CSegmentControlItem',{attrs:{"label":"10 km","value":"10"}}),_c('CSegmentControlItem',{attrs:{"label":"15 km","value":"15"}}),_c('CSegmentControlItem',{attrs:{"label":"25 km","value":"25"}})],1),_c('CMapMarker',{attrs:{"coordinates":_vm.coordinates}},[_c('div',{staticClass:"w-10 h-10 rounded-full shadow-sm bg-primary text-on-primary\n      inline-flex items-center justify-center"},[_c('CIcon',{attrs:{"name":"office-building","outline":"","size":"24"}})],1)]),(_vm.radius !== 'no')?_c('CMapCircle',{ref:"radiuscircle",attrs:{"id":"radiuscircle","radius":_vm.circleRadius,"center":_vm.circleCenter,"steps":30,"options":{
      layer: {
        layout: {},
        paint: {
          'fill-color': '#0080ff', // blue color fill
          'fill-opacity': 0.3,
        },
      }
    }}}):_vm._e(),_c('div',{staticClass:"absolute bottom-0 left-0 w-full z-20 flex justify-center align-center md:mb-3"},[(_vm.selectedPostalCodes.length > 0 || _vm.clickedPostalCode || _vm.radius !== 'no')?_c('div',{staticClass:"bg-gray-200 shadow-md",class:{
        'w-full mx-3 border-gray-400': _vm.clickedPostalCode,
        'bg-opacity-75 backdrop-filter backdrop-blur-md border rounded-lg':
          _vm.clickedPostalCode || _vm.selectedPostalCodes.length > 0,
        'md:px-2 md:py-2': _vm.selectedPostalCodes.length > 0,
      }},[(_vm.selectedPostalCodes.length > 0)?[_c('CButton',{staticClass:"mr-1 md:mr-2",on:{"click":_vm.resetSelection}},[_vm._v(" "+_vm._s(_vm.$t('cancelSelection'))+" ")]),_c('CButton',{attrs:{"variant":"primary","loading":_vm.submitLoading},on:{"click":_vm.submitSelected}},[_vm._v(" "+_vm._s(_vm.$t('deliveryArea.addSelected'))+" ")])]:(_vm.clickedPostalCode)?_c('ListSelectorItem',{attrs:{"data":_vm.clickedDeliveryArea,"standalone":""},on:{"update":_vm.onUpdate,"delete":function($event){return _vm.onDelete(_vm.clickedDeliveryArea.id)}}}):(_vm.selectedPostalCodes.length === 0 && _vm.radius !== 'no')?_c('CButton',{on:{"click":_vm.selectFromRadius}},[_vm._v(" "+_vm._s(_vm.$t('deliveryArea.addInRadius'))+" ")]):_vm._e()],2):_vm._e()]),_c('Portal',{attrs:{"to":"overlays"}},[_c('CModal',{attrs:{"variant":"dialog","header-icon":"question-mark-circle","button-props":{ hideCancel: true }},on:{"submit":_vm.closeMapHelp,"close":_vm.closeMapHelp},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('help'))+" ")]},proxy:true},{key:"submit",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('ok'))+" ")]},proxy:true}]),model:{value:(_vm.mapHelp),callback:function ($$v) {_vm.mapHelp=$$v},expression:"mapHelp"}},[_vm._v(" "+_vm._s(_vm.$t('deliveryArea.help.useMapToSelect'))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }