<template>
  <CTextField
    :label="$t('address.postalCode')"
    :data="currentPostalCodeResults"
    :debounce-typing="150"
    :clear-on-select="false"
    :value="value"
    field="postalCode"
    autocomplete-component
    :autocomplete-menu-class="menuClass"
    v-bind="{ ...$attrs }"
    @typing="searchPostalCode"
    @select="postalCodeSelect"
  >
    <template v-slot="props">
      {{ props.option.postalCode }} {{ props.option.city }}
    </template>
  </CTextField>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { SEARCH_POSTAL_CODES } from '@/store/actionTypes';
import { RESET_POSTAL_CODE_SEARCH } from '@/store/mutationsTypes';
import { CURRENT_POSTAL_CODE_RESULTS } from '@/store/gettersTypes';

export default {
  inheritAttrs: false,

  props: {
    countryShort: {
      type: String,
      default: null,
    },
    value: {
      type: String,
      default: null,
    },
    searchMinLength: {
      type: Number,
      default: 3,
    },
    menuClass: {
      type: String,
      default: 'max-h-[250px]',
    },
  },

  data: () => ({
    selectedPostalCode: null,
  }),

  computed: {
    ...mapGetters([CURRENT_POSTAL_CODE_RESULTS]),
    selectedId() {
      if (this.selectedPostalCode) return this.selectedPostalCode.id;
      return null;
    },
  },

  methods: {
    ...mapActions([SEARCH_POSTAL_CODES]),
    ...mapMutations([RESET_POSTAL_CODE_SEARCH]),
    searchPostalCode(value) {
      const currentCountry = this.countryShort;
      const isNan = Number.isNaN(Number(value));
      this.$emit('input', value);
      if (value.length >= this.searchMinLength) {
        const searchString = currentCountry && !isNan ? `${currentCountry}-${value}` : value;
        this.searchPostalCodes(searchString).then(() => {
          if (value.length === 5 && this.currentPostalCodeResults.length > 0) {
            this.postalCodeSelect(this.currentPostalCodeResults[0]);
          } else if (value.length === 5) {
            this.postalCodeSelect(null);
          }
        });
      } else {
        this.resetPostalCodeSearch();
      }
    },
    postalCodeSelect(data) {
      this.selectedPostalCode = data;
      this.$emit('select', data);
      this.$emit('select-id', this.selectedId);
    },
  },
};
</script>
