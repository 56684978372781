var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CModal',{attrs:{"show":_vm.show,"submit-form":"create-update-delivery-area-form","submit-disabled":_vm.submitDisabled,"submit-loading":_vm.submitLoading,"variant":"default"},on:{"toggle":_vm.onToggle},scopedSlots:_vm._u([{key:"header",fn:function(){return [(_vm.updateMode)?[_vm._v(" "+_vm._s(_vm.$t('deliveryArea.update', { postalCodeWithCity: ((_vm.updateData.location.postalCode) + " " + (_vm.updateData.location.city)) }))+" ")]:[_vm._v(" "+_vm._s(_vm.$t('deliveryArea.add'))+" ")]]},proxy:true},{key:"submit",fn:function(){return [(_vm.updateMode)?[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")]:[_vm._v(" "+_vm._s(_vm.$t('create'))+" ")]]},proxy:true}])},[_c('CForm',{attrs:{"id":"create-update-delivery-area-form","custom-save-button":"","error-fields-response":_vm.errorFieldsResponse},on:{"submit":_vm.onSubmit}},[(!_vm.updateMode)?_c('PostalCodeSearch',{directives:[{name:"autofocus",rawName:"v-autofocus"}],attrs:{"country-short":_vm.thisMerchant.countryShort,"menu-class":"max-h-[180px]","required":"","custom-error":_vm.postalCodeError},on:{"select-id":_vm.onPostalCodeSelect}}):_vm._e(),_c('CTextField',{directives:[{name:"autofocus",rawName:"v-autofocus",value:(_vm.updateMode),expression:"updateMode"}],attrs:{"label":_vm.$t('deliveryArea.additionalPrice'),"icon":"currency-euro","input-class":"slashed-zero tabular-nums","mask":{
        prefix: '',
        groupSeparator: '.',
        radixPoint: ',',
        alias: 'numeric',
        digits: 2,
        digitsOptional: false,
        textAlign: 'left',
        allowMinus: false,
        rightAlign: false,
        min: 0,
        max: 9999,
      }},scopedSlots:_vm._u([{key:"message",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('deliveryArea.help.additionalPrice'))+" ")]},proxy:true}]),model:{value:(_vm.formData.additionalPrice),callback:function ($$v) {_vm.$set(_vm.formData, "additionalPrice", $$v)},expression:"formData.additionalPrice"}}),_c('CSwitch',{model:{value:(_vm.formData.ignoreDeliveryDistancePrice),callback:function ($$v) {_vm.$set(_vm.formData, "ignoreDeliveryDistancePrice", $$v)},expression:"formData.ignoreDeliveryDistancePrice"}},[_vm._v(" "+_vm._s(_vm.$t('deliveryArea.ignoreDeliveryDistancePrice'))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }